body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.CampaignCreator {
    background-color: #f7fbfc;
    height: calc(100vh - 7.25rem);
    padding-left: 2vw;
    padding-right: 3vw;
    padding-top: 3%;
    text-align: left;

    .Btn {
        margin-left: 20%;
        width: 46.5%;
    }

    .Form {
        grid-column-start: 1;
        grid-row: 1;
    }

    .TemplatePreview {
        grid-column-start: 2;
        grid-row: 1;
    }

    .TableHolder {
        display: grid;
        grid-column-end: 4;
        grid-column-start: 1;
    }

    .Label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.5em;
        font-weight: bold;
    }

    .Head {
        margin-left: 0;
    }

    li {
        list-style: none;
    }
}
