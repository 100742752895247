@import 'assets/sass/_typography';
@import 'assets/sass/_devices';
@import 'assets/sass/_utilities';
@import 'assets/sass/colors';

.main-head {
    align-items: center;
    background-color: $engage-dark;
    box-shadow: 0 0.2rem 0.4rem #00000029;
    color: var(--white);
    display: flex;
    flex-direction: row;
    font-family: $montserrat;
    grid-area: header;
    height: 3.75rem;
    justify-content: space-between;
    padding: 0.25rem 1.5rem;

    @include desktop() {
        box-shadow: none;
    }

    .logo-link {
        border: 0;
        text-decoration: none;
    }

    .login-link {
        color: var(--white);
    }

    .mobile {
        @include desktop() {
            display: none;
        }
        i {
            font-size: 18px;
        }
    }

    .desktop {
        display: none;

        @include desktop() {
            box-shadow: none;
            display: block;
        }
    }

    .tablet {
        display: none;

        @include tablet() {
            box-shadow: none;
            display: block;
        }
    }

    .logo {
        height: 4rem;
        width: 7rem;
    }

    h3 {
        font-weight: 100;
    }

    .filler {
        flex: 1;
    }

    .fa-bars {
        cursor: pointer;
        font-size: 24px;
        padding: 1.5rem;
    }

    .input {
        align-items: center;
        background-color: var(--white);
        border-radius: 0.2rem;
        display: flex;
        flex-direction: row;
        height: 1.75rem;
        padding: 0.3rem;
        width: 15rem;

        .fa-search {
            color: var(--gray-45);
            width: 1.5rem;
        }

        input {
            background-color: inherit !important;
            border: 0;
            color: var(--text-body);
            height: 1.75rem;
            outline: none;
            width: 13rem;
        }
    }

    .MuiSvgIcon-root {
        padding: 0rem 0.286rem;
    }
}

.logon-button {
    @include setFont(20px, $SemiBold);

    background-color: var(--primary);
    border: none;
    color: var(--white);
}

.search-overlay {
    color: var(--black);
    .search-overlay-mask {
        background: #808080 0% 0% no-repeat padding-box;
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: fixed;
        top: 4rem;
        width: 100%;
        z-index: 50;
    }

    .search-overlay_content {
        background: #fff 0% 0% no-repeat padding-box;
        border-radius: 0 0 0.7rem 0.7rem;
        box-shadow: 0 0.2rem 0.4rem var(--shadow);
        left: 0;
        min-height: 10rem;
        position: absolute;
        top: 0.45rem;
        width: 100%;
        z-index: 50;
    }
}
