.SegmentLibrary {
    background-color: #f7fbfc;
    border: 0.1rem solid var(--gray-80);
    margin: 0 0 2rem;
    overflow: auto;

    &__Body {
        // background-color: #fff;
        padding: 2rem;
        width: 70%;
    }

    &__Header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
    }

    .SegmentTiles > * {
        margin: 1.5rem 0rem 0;
    }

    &__Title {
        font-size: x-large;
    }
}
