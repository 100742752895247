.SubscriptionForm {
    margin: 0 auto;
    width: 100%;
}

.MuiInputBase-input {
    color: black;
    &::placeholder {
        color: black;
    }
}

.MissingField {
    color: #f00;
    font-size: 14px;
    margin: 0 0 0 0.1rem;
}
