.TimeHistoryGraph {
    border: 1px solid #c8cfd6;
    padding: 1rem;
    .TimeSpan {
        font-size: large;
        font-weight: 600;
        padding-bottom: 0.75rem;
        text-align: center;
    }
    .custom-tooltip {
        font-size: medium;
        .label {
            font-weight: bold;
            margin: 0;
        }
        .opens {
            margin: 0;
        }
        .clicks {
            margin: 0;
        }
        .date {
            margin-bottom: 0;
        }
    }
}
