.InputForm {
    max-height: 73vh;
    overflow-y: auto;
    padding: 2rem 5% 7.875rem;
    text-align: left;
}

.CampaignName {
    color: #07090c;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
}

.ArticleBtns {
    margin-bottom: 1em;

    .AddBtn {
        width: 47.5%;
    }

    .RemoveBtn {
        margin-left: 5%;
        width: 47.5%;
    }
}

.Validation {
    border: 0.15rem solid red;
}

.InputLabel {
    color: #07090c;
    display: block;
    font-family: Roboto;
    font-size: 14px;
    margin: 2% 0 1%;
}

.ArticleLabel {
    color: #07090c;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    margin: 1.6em 0 0.5em;
}

.CharCount {
    display: block;
}

.CharRemain {
    color: #40576c;
    display: block;
    font-family: Roboto;
    font-size: 12px;
    padding-top: 0.25em;
}

.Newsletter .Press .Event {
    min-width: 80%;
}

textarea {
    height: 10em;
    resize: none;
}

.ClearBtnGrid {
    text-align: right;
}

.boldCount {
    color: #000;
}

#resetPhoneBtn {
    margin-top: 1em;
}

.Contact {
    margin-bottom: 0.5em;
}

.DateInput {
    margin-bottom: 1em;
}

.InputGroup {
    margin-top: 1em;
}
