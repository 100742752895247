@import 'assets/sass/_typography';

.top-nav {
    box-shadow: 0 0.2rem 0.4rem var(--shadow);
    display: none;
    min-height: 3.7rem;

    @include desktop() {
        display: block;
    }

    @include tablet() {
        display: block;
    }
}

.nav-button {
    @include setFont(18px);

    border-radius: 0;
    color: #bdc2c5;
    height: inherit;
    margin-bottom: 0.2em;
    margin-right: 1.75rem;
    min-width: 0;
    padding-bottom: 1rem;
    text-align: left;

    &.active {
        @include setFont(18px, $SemiBold);

        border-bottom: 0.3rem solid #a3b0bb;
        color: #fff;
        padding-bottom: 0.7rem;
    }

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.top-nav-flex,
.nav-links {
    display: flex;
    flex-direction: row;
}

.nav-links {
    padding: 1.3rem 0 0;

    &.nav-links_button {
        padding: 0.8rem 0 0;
    }
}

.top-nav-flex {
    justify-content: space-between;
    padding: 0 3.5rem;
}
