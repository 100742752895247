//
// Device Breakpoints
// Write your code using mobile first styles and override for tablet/desktop using the
// mixins defined below.
//
// Example Usage:
// .Footer {
//     display: flex;
//     flex-direction: column; // arrange items vertically on mobile devices
//
//     @include desktop {
//         flex-direction: row; // arrange items horizontally on desktop
//     }
// }
//
$tablet-width: 768px; //768px;
$desktop-width: 1024px;

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        //@media (max-width: $tablet-width) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$desktop-width - 1}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin sm-mobile {
    @media (max-width: 600px) {
        @content;
    }
}

$xl: 1280px;
$lg: 1024px;
$md: 768px;
$sm: 640px;

@mixin xl {
    @media (min-width: $xl) {
        @content;
    }
}

@mixin lg {
    @media (min-width: $lg) {
        @content;
    }
}

@mixin md {
    @media (min-width: $md) {
        @content;
    }
}

@mixin sm {
    @media (min-width: $sm) {
        @content;
    }
}
