.#{$rt-namespace}__toast-container {
    box-sizing: border-box;
    color: #fff;
    padding: 0;
    position: fixed;
    transform: translate3d(0, 0, #{$rt-z-index}px);
    width: auto;
    z-index: $rt-z-index;

    &--top-left {
        left: 1em;
        top: 1em;
    }

    &--top-center {
        left: 50%;
        top: 1em;
        transform: translateX(-50%);
    }

    &--top-right {
        right: 1em;
        top: 1em;
    }

    &--bottom-left {
        bottom: 1em;
        left: 1em;
    }

    &--bottom-center {
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    &--bottom-right {
        bottom: 1em;
        right: 1em;
    }
}

@media #{$rt-mobile} {
    .#{$rt-namespace}__toast-container {
        left: 0;
        margin: 0;
        padding: 0;
        width: 100vw;

        &--top-left,
        &--top-center,
        &--top-right {
            top: 0;
            transform: translateX(0);
        }

        &--bottom-left,
        &--bottom-center,
        &--bottom-right {
            bottom: 0;
            transform: translateX(0);
        }

        &--rtl {
            left: initial;
            right: 0;
        }
    }
}
