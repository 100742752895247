.Info {
    color: #151d24;
    font-family: Roboto;
    font-size: 14px;
    margin: 0.5em 0;
    width: 100%;
}
.Info_Number {
    color: #151d24;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    margin: 0.5em 0;
    padding-left: 0.2rem;
    width: 100%;
}
.DashContainer {
    padding: 0;
    position: relative;
    text-align: center;
}
.Dashes {
    border-top: 2px dotted #07090c;
    margin-top: 1.1rem;
    opacity: 0.16;
}
