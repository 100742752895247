.ReviewUpload {
    text-align: left;

    .ReviewFileContainer {
        border-bottom: 1px solid #eceff1;
        // padding: 1% 0;
    }

    .ReviewFileSubContainer,
    .UploadResultsContainer {
        margin: 0 4rem;
        padding: 0;
    }

    .ViewContactsBtn {
        margin-top: 0.5rem;
    }

    .Title {
        color: #40576c;
        font-family: Roboto;
        font-size: 16px;
        margin: 1em 0;
    }

    .SubscriptionQuestion {
        margin-top: 5%;
    }
    .FileUploadedText {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 0.5rem;
    }

    .MissingField {
        margin: 0;
    }
}
