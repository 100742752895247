@import 'assets/sass/_typography';
@import 'assets/sass/_devices';

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

.icon-container {
    display: flex;
    line-height: 1.5em;
    padding-bottom: 0.3rem;

    .icon {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 3rem;
        justify-content: center;
        margin: 1rem 0.3rem 0.5rem 1rem;
        padding: 0 0.3rem;
        position: relative;
        width: 3rem;

        .uikit_Icon {
            background: none;
            color: #fff;
            padding: 0.7rem 1.4rem;
        }

        .icon-container_title {
            font-weight: $Medium;
        }

        .uikit_badge {
            .uikit_Icon {
                background: none;

                svg {
                    font-size: 1.125em;
                }
            }
        }

        .avatar {
            img {
                border-radius: 50%;
                width: 3rem;
            }
        }

        .overlay {
            animation: growDown 300ms ease-in-out forwards;
            // display: none;
            background-color: #fff;
            border: 0.1rem solid var(--primary);
            color: var(--black);
            min-height: 14rem;
            min-width: 22rem;
            position: absolute;
            right: -1.75rem; // offset for profile
            top: 4rem;
            transform-origin: top center;
            z-index: 33;

            .overlay_top {
                align-items: center;
                display: flex;
                height: 3.75rem;
                justify-content: space-between;
                padding: 0.375rem 0.375rem 0.375rem 0.75rem;

                .title {
                    color: var(--black);
                    .heading {
                        @include setFont(18px, $SemiBold);

                        width: 14rem;
                    }

                    .sub-heading {
                        @include setFont(14px, $Regular);
                    }
                }
            }

            .overlay_content {
                border-top: 0.1 solid var(--primary);
                padding: 0.75rem;
            }
        }
    }
}
