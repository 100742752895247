.CampaignDetails {
    height: 64vh;
    margin: 5% 7% 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;

    .title {
        font-size: 20px;
        margin-bottom: 3%;
    }

    .InputLabels {
        font-family: Roboto;
        font-size: 14px;
        margin: 2% 0 1.5%;
    }

    .Inputs {
        border-radius: 0.3rem;
        margin: 0% 0 1.5%;
        margin-bottom: 0;
        width: 70%;

        &.Validation {
            border: 0.15rem solid red;
        }
    }

    .SenderName {
        width: 60%;
    }

    .Subject {
        width: 100%;
    }

    .SenderSelect {
        margin-bottom: 1.7em;
        width: 60%;
        z-index: 3 !important;
    }

    .Message {
        margin-top: 3%;
    }

    .SenderInfo {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
    }
}
