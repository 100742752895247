@keyframes #{$rt-namespace}__flipIn {
    from {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    }

    40% {
        animation-timing-function: ease-in;
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }

    60% {
        opacity: 1;
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}

@keyframes #{$rt-namespace}__flipOut {
    from {
        transform: perspective(400px);
    }

    30% {
        opacity: 1;
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    }

    to {
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    }
}

.#{$rt-namespace}__flip-enter {
    animation-name: #{$rt-namespace}__flipIn;
}

.#{$rt-namespace}__flip-exit {
    animation-name: #{$rt-namespace}__flipOut;
}
