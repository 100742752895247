.DemoBanner {
    align-items: center;
    background-color: #1a44b4;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;

    &__Text {
        color: #fff;
    }
}

.DemoBannerModal {
    &__Text {
        white-space: pre-line;
    }
}

@media only screen and (max-width: 600px) {
    .DemoBanner {
        display: grid;
    }

    #a {
        grid-column: 1;
        grid-row: 1;
        text-align: center;
        font-size: 14px;
    }
    #b {
        grid-column: 1;
        grid-row: 2;
        text-align: center;
        font-size: 14px;
    }
}
