/* //Mile 2 Unique Colors */
:root {
    --dark-green: #016848;
    --medium-green: rgba(1, 104, 72, 0.88);
    --medium-green-hover: rgba(1, 104, 72, 0.68);
    --dark-blue: #1b3a43;
    --light-green: #d0df63;
    --light-blue: #c5e8f3;
    --sso-blue: #138dd6;
    --sso-blue-darker: #0b4f78;
    --sso-blue-lighter: #5ebaf1;
    --deep-grey: #2e2e2e;

    /* LIGHT/DARK */
    --lighter: 78%;
    --light: 68%;
    --normal: 60%;
    --dark: 50%;
    --darker: 35%;

    /* FONT SIZE */
    --font-size-tiny: 12px;
    --font-size-normal: 14px;
    --font-size-large: 18px;
    --font-size-xlarge: 20px;
    --font-size-xxlarge: 24px;
    --font-size-xxxlarge: 30px;

    /* FONT WEIGHT */
    --font-weight-light: 200;
    --font-weight-normal: 400;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    --deadline-dark: #5b8495;
    --deadline: #99bdcc;
    --deadline-light: #aecddb;

    --success-banner-light: #b8dbcb;
    --success-banner: #9dc7b4;
    --success-banner-dark: #578e76;

    --inprogress-banner-light: #b8dbcb;
    --inprogress-banner: #eabc72;
    --inprogress-banner-dark: #578e76;

    --review-banner: #e6a1a1;

    --notstarted-banner: #c6c6c6;
    /* //Basic Component Color Palette */
    --primary: #0b6777;
    --primary-dark: #1e3251;

    --primary-2: #a6cdd5;
    --primary-3: #d8dee2;
    --primary-4: #abbec9;
    --primary-5: #546e7e;
    --primary-7: #242424;
    --primary-8: #225b7e;
    --primary-accent: #b55b00;

    --primary-hover: #44639b;
    --primary-disabled: #abadb3;
    --primary-disabled-hover: #d9e5f6;

    --secondary: #a6cdd5;
    --secondary-hover: #5b99a5;
    --secondary-disabled: #85c8d5;
    --secondary-disabled-hover: #bed6db;

    --secondary-6: #1c5980;
    --secondary-7: #044a57;

    --alert: #ed0000;
    --alert-background: #fff7f7;
    --alert-hover: #f08888;
    --alert-disabled: #f0b9b7;
    --alert-disabled-hover: #fbeaec;
    --alert-dark: #8a0000;
    --alert-shadow: #d0181859;
    --alert-notice: #ceb600;

    --success: #228646;
    --success-background: #f5fff8;
    --success-hover: #549b87;
    --success-disabled: #74d2b8;
    --success-disabled-hover: #b2d6cc;
    --success-dark: #175a30;
    --success-shadow: #3d8b6159;
    --border-navbar: #e2e4e8;

    --warning: #eaa118;
    --warning-hover: hsla(37, 74%, var(--normal), 1);
    --warning-darker: hsla(37, 74%, var(--dark), 1);
    --warning-disabled: hsla(37, 74%, var(--light), 1);
    --warning-disabled-hover: hsla(37, 74%, var(--lighter), 1);

    --notice: #fce117;

    /* // Gray scale */
    --white: #fff;
    --gray-99: #fdfdfd;
    --gray-99-5: #f8fafc;
    --gray-98: #f3f3f3;
    --gray-95: #f1f1f1;
    --gray-90: #edeaea;
    --gray-88: #dfe5f0;
    --gray-87: #ddd;
    --gray-85: #d0d5de;
    --gray-80: #ccc;
    --gray-78: #bac1d3;
    --gray-77: #b5c2d5;
    --gray-75: #c0c0c0;
    --gray-70: #b0b0b0;
    --gray-65: #bfc3cb;
    --gray-60: #999;
    --gray-55: #c4cad0;
    --gray-50: #808080;
    --gray-48: #abadb3;
    --gray-45: #707070;
    --gray-40: #696969;
    --gray-34: #53565a;
    --gray-30: #4d4d4d;
    --gray-20: #303030;
    --gray-15: #222;
    --gray-10: #171717;
    --black: #000;

    --translucent-gray-25: rgba(0, 0, 0, 0.25);
    --translucent-gray-5: rgba(0, 0, 0, 0.05);
    --translucent-primary: #486bac0d;
    --translucent-primary-darker: #486bac5b;

    --tint-50: rgba(255, 255, 255, 0.5);
    --tint-25: rgba(255, 255, 255, 0.25);
    --tint-15: rgba(255, 255, 255, 0.15);

    --shade-5: rgba(0, 0, 0, 0.05);
    --shade-10: rgba(0, 0, 0, 0.1);
    --shade-20: rgba(0, 0, 0, 0.2);
    --shade-40: rgba(0, 0, 0, 0.4);

    /* // Main colors for the site */
    --text: rgba(0, 0, 0, 0.91);
    --text-body: rgba(0, 0, 0, 0.71);
    --text-light: rgba(0, 0, 0, 0.61);
    --text-blue: rgba(27, 58, 67, 0.78);
    --background: var(--white);
    --links: var(--primary);
    --links-hover: #6092d7;

    --button-radius: 4px;
    --button-border: 2px;
    --global-radius: 4px;
    --global-shadow: 0px 1px 2px #00000029;

    --disabled-background: #f7f7f7;
}
