.UpdatePreferences {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    padding: 2rem 0;
    width: 100%;
}

.UpdatePreferencesForm {
    width: 40%;
}

.MuiInputBase-input {
    color: black;
    &::placeholder {
        color: black;
    }
}

.MissingField {
    color: #f00;
    font-size: 14px;
    margin: 0 0 0 0.1rem;
}
