.SegmentNameFinalStep {

    margin-top: 20px;
    
    .step {
        float: left;
        width: 30px;
        height: 30px;
        font-size: 1.25rem;
        background-color: #00208F;
        border-radius: 50%;
        margin-right: 5px;
    }

    .step-text {
        color: white;
        // font-weight: bold;
        bottom: 50%;
        width: 100%;
        text-align: center;
    }

    .box {
        padding: 1rem;
    }
    .MuiFormHelperText-root {
        text-align: right;
        font-size: 12px;
    }

    p {
        color: #0000008A;
        font-size: 14px;
    }
}