$rt-namespace: 'Toastify';
$rt-toast-width: auto !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 64px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: transparent !default;
$rt-color-dark: transparent !default;
$rt-color-info: transparent !default;
$rt-color-success: transparent !default;
$rt-color-warning: transparent !default;
$rt-color-error: transparent !default;

$rt-text-color-default: #aaa !default;
$rt-text-color-dark: #fff !default;

$rt-color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: 'only screen and (max-width : 480px)' !default;
$rt-font-family: sans-serif !default;
$rt-z-index: 9999 !default;
