@import 'assets/sass/colors';

.InfoPage {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 7.25rem);
    @media only screen and (max-width: 1029px) {
        height: auto;
    }

    .ColoredSection {
        background-color: #f7fbfc;
    }

    .TopSection {
        width: auto;
        padding-left: 2rem;

        @media only screen and (max-width: 970px) {
            height: auto;
            padding: 0 1rem;
        }

        &__Description {
            display: flex;
            flex-direction: column;
            justify-content: center;

            > * {
                font-weight: bold;
            }
            &--main {
                font-size: 2.25rem;
            }
        }

        img {
            width: 28rem;
            height: 16rem;
        }

        &__Graphic {
            margin: 2rem 0;
            text-align: center;
        }
    }

    .CurrentFeaturesSection,
    .FutureFeaturesSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem 16rem;
        text-align: center;

        @media only screen and (max-width: 970px) {
            height: auto;
            padding: 1.5rem 3rem;
        }

        &__Header {
            font-size: 1.5625rem;
            font-weight: bold;
        }

        &__Body {
            display: flex;
            justify-content: space-between;
        }
    }

    .FeatureTile {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 8rem;
        padding-top: 1.5rem;

        &__Background {
            align-items: center;
            background-color: $engage-light;
            border-radius: 100%;
            display: flex;
            height: 4rem;
            justify-content: center;
            width: 4rem;
        }

        &__Icon {
            color: $engage-primary;
        }

        &__Label {
            font-size: 0.875rem;
            font-weight: bold;
            padding-top: 0.5rem;
        }
    }
}
