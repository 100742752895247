.SignUp {
    margin: 2em auto;
    padding-bottom: 2rem;
    width: 40%;
}

.MuiInputBase-input {
    color: black;
    &::placeholder {
        color: black;
    }
}

.MissingField {
    color: #f00;
    font-size: 14px;
    margin: 0 0 0 0.1rem;
}

.OkBtn {
    margin-left: 1em;
    text-decoration: none;
}

.SubscribedBox {
    border: 2px solid gray;
    height: 15em;
    margin: 15vh auto;
    padding: 1vh 1rem;
    width: 32em;
}

.ResubscribeBox {
    border: 2px solid gray;
    height: 14em;
    margin: 15vh auto;
    padding: 1vh 0;
    width: 32em;
}

.SubscribeConfirmContainer {
    text-align: left;
}

.SubscribedText {
    font-family: Roboto;
    font-size: 14px;
}

.Heading {
    font-size: 16px;
    font-weight: bold;
}

.GridContainer {
    height: 12em;
    padding: 1vw;
}

.GridContainerSubed {
    height: 8em;
    padding: 1vw;
}
