.DuplicateContainer {
    margin: 0 4rem;
    text-align: left;
}
.QuestionText {
    color: #40576c;
    font-family: Roboto;
    font-size: 16px;
}
.RadioText {
    opacity: 0.98;
}
