@import 'assets/sass/colors';

.TemplateBtnContainer {
    margin: 10% 0 0;
    .TemplateButton {
        cursor: pointer;
        margin: 0;
        outline: 0 !important;
        :hover {
            animation-duration: 0.3s;
            transform: translate3d(-0.1rem, -0.1rem, 0);
        }
    }
    .TemplateSelected {
        border: 2px solid $engage-primary;
        border-radius: 5px;
    }

    .TemplateName {
        font-family: Roboto;
        font-size: 14px;
        margin-top: 5%;
    }

    .Selected {
        font-weight: bold;
    }

    .TemplateBtnGrid {
        text-align: center;
    }
}
