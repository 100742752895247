.OkBtn {
    margin-left: 1em;
    text-decoration: none;
}

.UnauthorizedBox {
    border: 2px solid gray;
    height: 18em;
    margin: 15vh auto;
    padding: 1vh 0;
    width: 32em;
}

.UnauthorizedBox {
    border: 2px solid gray;
    height: 14em;
    margin: 15vh auto;
    padding: 1vh 0;
    width: 32em;
}

.UnauthorizedContainer {
    text-align: left;
}

.UnauthorizedText {
    font-family: Roboto;
    font-size: 14px;
}

.Heading {
    font-size: 16px;
    font-weight: bold;
}

.GridContainer {
    height: 12em;
    padding: 1vw;
}

.GridContainerResub {
    height: 8em;
    padding: 1vw;
}
