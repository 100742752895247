.AnalyticBorder {
    border-right: 1px solid #c8cfd6;
    padding-right: 0.3rem;
    height: 4.5rem;
}

.AnalyticPercents {
    font-size: 18px;
    font-family: Roboto;
    font-weight: bold;
    margin: 0 0 0 0;
}

.AnalyticLabel {
    font-size: 12px;
    font-family: Roboto;
    color: #151d24;

    margin: 0 0 0 0;
}

.AnalyticPadding {
    padding-left: 0.5rem;
}

.NoLine {
    border-right: none;
    padding-left: 1rem;
}
