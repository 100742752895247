@import 'assets/sass/colors';

.additionalContacts {
    .step {
        float: left;
        width: 30px;
        height: 30px;
        font-size: 1.25rem;
        background-color: #00208f;
        border-radius: 50%;
        margin-right: 5px;
    }

    .step-text {
        color: white;
        // font-weight: bold;
        bottom: 50%;
        width: 100%;
        text-align: center;
    }

    .box {
        padding: 1rem;
    }

    p {
        color: #0000008a;
        font-size: 14px;
    }

    .ContactChip {
        background-color: $engage-light !important;
        border: #c8cfd6 0.1rem solid !important;
        margin: 0.4rem;
    }

    #AddContactPlusIcon {
        margin-right: 0.5rem;
    }
}
