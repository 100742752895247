@import 'assets/sass/colors';

.Contacts {
    border: 0.1rem solid var(--gray-80);

    .Window {
        border-left: 0.1rem solid var(--gray-80);
        padding: 1rem 0;
    }

    &__FilterSidebar {
        float: left;
        overflow: hidden;
        width: 22rem;

        &--header {
            color: #07090c;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 600;
            margin-left: 1rem;
            padding-top: 1.25rem;
        }
    }

    &__ContactTable {
        border-left: 0.1rem solid var(--gray-80);
        overflow: hidden;

        &--topRow {
            height: 3.5rem;
            margin: 1rem 1rem 0.5rem;
        }

        &--filterChipRow {
            height: 3rem;
            overflow-y: auto;
        }

        .DrawerButtons {
            height: 1.5rem;
        }
    }

    .Chip {
        background-color: $engage-light !important;
        border: #c8cfd6 0.1rem solid !important;
        margin: 0.25rem 0.5rem 0.5rem;
    }
}

#noApplicableContacts {
    margin: 0 1rem;
}

#SnackMsg {
    margin: auto;
    text-align: center;
}
