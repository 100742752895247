.CampaignReport {
    background-color: #f7fbfc;
    border: 0.1rem solid var(--gray-80);
    margin: 0 0 2rem;
    overflow: auto;

    .AnalyticsContainer {
        padding: 1rem 2rem;

        &__ToggleSwitch {
            .MuiSwitch-thumb {
                color: #3f51b5 !important;
            }
            .MuiSwitch-track {
                background-color: #3f51b5 !important;
            }
        }
    }

    &__Body {
        background-color: #fff;
        width: 70%;
        margin: 2rem;
        border: 1px solid #c8cfd6;
    }

    &__Header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
    }

    .SwitchContainer {
        padding-top: 0.8rem;
    }

    .RecentActivityContainer {
        text-align: right;
    }

    .RecentActivityText {
        font-family: Roboto;
        font-size: 12px;
        color: #151d24;
        margin: 0;
        padding-top: 1.5rem;
        font-style: italic;
    }
    hr {
        margin: 0 -2rem;
    }

    .ExitReportBtn {
        margin: 2rem 0 0 2rem;
    }
}
