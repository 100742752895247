@import 'assets/sass/colors';

.SegmentTile {
    background-color: #fff;
    border: 1px solid #c8cfd6;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    width: 100%;

    #SegmentTitle {
        display: inline-block;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        margin-left: 1rem;
    }

    #SegmentDescL {
        margin-left: 0.75rem;
    }

    .SegmentDesc {
        color: #5a7085;
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
    }

    #SegmentContentL {
        margin-left: 0.75rem;
    }

    .SegmentContent {
        color: #5a7085;
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        overflow-wrap: anywhere;
    }

    #Chips {
        margin-left: 0.5rem;
    }

    #Delete {
        color: #c62828;
        font-family: Roboto;
        font-size: 14px;
        &:hover {
            background-color: #ffebee;
        }
    }

    #Create,
    #Edit {
        font-family: Roboto;
        font-size: 14px;
    }
}
