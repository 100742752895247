.Wizard {
    background-color: #fff;
    border: #c8cfd6 0.1rem solid;
    min-width: 40rem;
    text-align: center;
}

.ExitBtn {
    background: none;
    font-size: 12px;
    margin: 2% 0 0;
    outline: none;
    text-align: left;
    width: 12rem;
}

.root {
    margin-top: 10%;
}

.Heading {
    padding: 0 4em;
    text-align: left;
}

.MuiStepper-root.MuiPaper-root {
    background-color: #f7fbfc;
}

.StepBtnsWrapper {
    min-width: 15rem;
}

.StepBtns {
    background-color: #f7fbfc;
    color: #0c1256;
    cursor: pointer;
    font-size: 12.9px;
    outline: none !important;
}

.StepBtns:disabled {
    color: #c8cfd6;
    cursor: auto;
}

.StepSelected {
    font-weight: bolder;
}

.BtnSectionGrid {
    width: 100%;
}

.BtnSection {
    border-top: 0.1rem #c8cfd6 solid;
    margin-top: 2.5%;
    padding: 0.8rem 2.5rem;
    text-align: left;

    &__Review {
        @extend .BtnSection;

        background-color: #fff;
        bottom: 0;
        position: absolute;
        width: 45%;
    }

    &__Left {
        float: left;
    }

    &__Right {
        float: right;
        min-width: 50%;
        text-align: end;
    }
}

#BackBtn {
    margin: 0.5rem 0.5rem 0.5rem 1.2rem;
}

#ContinueBtn {
    margin: 0.3rem 1.8rem 0.3rem 1.2rem;
}

.Clearfix {
    clear: both;
    z-index: 3 !important;
}

.Clearfix::after {
    clear: both;
    content: '';
    display: table;
}

.Shake {
    animation: 1.25s shake;
    animation-iteration-count: 1;
}

@keyframes shake {
    0% {
        transform: skewX(-25deg);
    }
    5% {
        transform: skewX(25deg);
    }
    10% {
        transform: skewX(-25deg);
    }
    15% {
        transform: skewX(25deg);
    }
    25% {
        transform: skewX(0deg);
    }
    100% {
        transform: skewX(0deg);
    }
}

@media all and (max-width: 1100px) {
    .Review {
        width: 100%;
    }
}
