@import 'assets/sass/_typography';
@import 'assets/sass/_devices';
@import 'assets/sass/_utilities';
@import 'assets/sass/colors';

.external-main-head {
    align-items: center;
    align-items: flex-end;
    background-color: #2d395a;
    box-shadow: 0 0.2rem 0.4rem #00000029;
    color: var(--white);
    display: flex;
    flex-direction: row;
    font-family: $montserrat;
    grid-area: header;
    height: 5.6rem;
    justify-content: space-between;
    padding: 0.25rem 1.25rem;

    .logo-link {
        border: 0;
        text-decoration: none;
    }

    .SBIRlogo {
        height: 3.45rem;
        margin-bottom: 0.6%;
        margin-left: 9.3%;
        width: 11.75rem;
    }

    .LogoutButton {
        color: var(--white);
        margin-bottom: 1rem;
    }
}
