.UploadStepper {
    background-color: #f7fbfc;
    height: calc(100vh - 3.75rem);
    padding-left: 2vw;
    padding-right: 3vw;
    padding-top: 3%;
    text-align: left;
}

.WizardTitleText {
    font-size: 20px;
    font-weight: bold;
    margin: 2.5rem 0 1.5rem;
}

.UploadExitModalText {
    color: rgba(0, 0, 0, 0.54);
}
