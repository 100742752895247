html,
body {
    margin: 0;
    padding: 0;
}

.DataTable {
    .SmallPadding {
        border-radius: 10%;
        padding: 0.3rem;
    }

    .Table {
        height: calc(100vh - 23.5rem);

        .Checkbox {
            height: 1rem;
            width: 1rem;
        }
    }

    .SortIconSpan {
        display: none;
    }
    .Th:hover {
        .SortIconSpan {
            display: contents;
        }
    }
    .Header__IsSorted {
        .SortIconSpan {
            display: contents;
        }
    }
}
