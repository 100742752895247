.container {
    padding: 0;
    margin: 0 4rem;
    text-align: left;
    width: 60%;

    .MissingField {
        color: #f00;
        font-size: 14px;
        margin: 0 0 0.5rem 0.1rem;
    }

    .UploadContainer {
        display: block;
        padding: 0;
        text-align: center;
    }

    .TextContainer {
        text-align: left;
    }

    .UploadedText {
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        color: #07090c;
    }

    .ChipContainer {
        display: block;

        padding: 0;
        text-align: left;
    }

    .dropzone {
        background-color: rgb(240, 240, 240);
        border: 2px dashed rgb(186, 186, 186);
        cursor: pointer;
        height: 35vh;
        margin: auto;
        outline: none;
        text-align: center;
        width: 34em;
    }

    .DropzoneWrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        height: 100%;
        justify-content: center;
    }

    .UploadIcon {
        color: #5a7085;
        font-size: 60px !important;
    }

    .DirectionText {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0.5em;
    }

    .FormatText {
        color: #007bff;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
    }

    .UploadSample {
        font-size: 14px;
        margin-top: 4%;
    }

    .Download {
        font-size: 14px;
        font-weight: bold;
        margin-top: 2%;
        text-decoration: underline;
    }
}
