//
// Typography Variables and Mixins
// Define your font stacks, sizes, and weights here.
//
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');
@import './_devices';

// --- Global Variables ---
$global-width: rem-calc(1200) !default;

$global-radius: 0 !default;
$global-lineheight: 1.5 !default;
$global-font-size: 100% !default;
$white: #fefefe !default;
$body-background: $white !default;
$global-text-direction: ltr !default;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);
$global-lineheight: 1.5 !default;
$global-font-size: 14px;
// Font Fam
$montserrat: 'Montserrat', sans-serif;
$opensans: 'Open Sans', sans-serif;

// Font For Content
$fontstack-body: $montserrat;
$fontstack-headings: $montserrat;

// Font Weight
$fontweight-thin: lighter;
$fontweight-normal: 400;
$fontweight-bold: 600;

$Regular: 400;
$Medium: 500;
$SemiBold: 600;
$Bold: 700;

// Font Size
$global-weight-normal: 14px;
$fontsize-small: 12px;
$fontsize-normal: 14px;
$fontsize-large: 22px;
$fontsize-xl: 30px;
$fontsize-xxl: 48px;
$fontsize-xxxl: 64px;
$fontsize-display: 62px;

//
// Utility function to set both the font-size and
// line height (Important when your text spans more
// than one line).
//
@mixin fontsize($base) {
    font-size: $base;
    line-height: $base * 1.15;
}

@mixin fontsize_altlineheight($base) {
    font-size: $base;
    line-height: $base * 1;
}

@mixin setFont($size, $weight: $fontweight-normal, $family: $montserrat) {
    font-family: $family;
    font-size: $size;
    font-weight: $weight;
}

.uppercase {
    text-transform: uppercase;
}

.bold-text {
    font-weight: $fontweight-bold;
}

.thin-text {
    font-weight: $fontweight-thin;
}

.blue-text {
    color: var(--primary);
}

.red-text {
    color: var(--alert-disabled);
}
