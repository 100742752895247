.ErrorCard {
    background-color: #fdeceb;
    border: 2px solid #c62828;
    width: 32rem;
    padding: 0.5rem;
    display: flex;
    margin-bottom: 1rem;

    .ErrorIcon {
        margin-top: 0.5rem;
    }
    .ErrorMsg {
        color: #c62828;
        font-size: 13px;
        font-family: Roboto;
        margin-bottom: 0;
        padding-left: 1rem;
    }
}
