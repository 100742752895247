.CampaignReportHeader {
    border-bottom: 0.1rem solid var(--gray-80);
    padding: 2rem;

    &__Title {
        font-size: x-large;
        font-weight: 600;
        padding-bottom: 1.25rem;
    }
    &__TitleSection {
        padding-bottom: 0.75rem;
    }
    &__ValueSection {
        padding-bottom: 1.5rem;
    }

    .SegmentNameChip {
        height: 1.75rem;
        margin-left: 1rem;
    }
}
