.ImportManagement {
    width: 77vw;
    &__TextBody {
        padding: 0 4em;
        text-align: left;
    }
}

#matchingColumnsTable {
    border: 1px solid #c8cfd6;
}

.CustomTableHeader {
    display: flex;
}

.OpenMatchingColumnsFormButton {
    padding-bottom: 0.75rem;
}

.ColumnsTooltip {
    display: inline-block;
}
