@import 'assets/sass/colors';

.FilterBuilder {
    .text {
        width: 100%;
        height: 40px;
    }

    #height {
        background-color: blueviolet;
        height: 40px;
    }

    .step {
        float: left;
        width: 30px;
        height: 30px;
        font-size: 1.25rem;
        background-color: #00208f;
        border-radius: 50%;
        margin-right: 5px;
    }

    .step-text {
        color: white;
        bottom: 50%;
        width: 100%;
        text-align: center;
    }

    .box {
        padding: 1rem;
        margin-bottom: 1.5rem;
        .form {
            width: 100%;
            .line {
                font-size: 16px;
                display: flex;
                align-items: center;
            }
            .full {
                width: 100%;
                height: 40px;
            }
        }
    }

    .numLabel {
        font-size: 14px;
        margin-top: 1rem;
    }

    .numDisplay {
        font-size: x-large;
        font-weight: bold;
    }
    .AndOr {
        font-weight: 700;
    }

    .pSubHead {
        color: #0000008a;
        font-size: 14px;
    }

    .label {
        color: black;
    }
}
