// Create two unequal columns that floats next to each other
.LayoutColumn {
    float: left;
    padding: 1rem;

    &__left {
        padding-right: 1rem;
        width: 20%;
    }

    &__right {
        width: 80%;
    }

    &__full {
        width: 100%;
    }
}

// Need flexible columns to accomodate for icons & other components
.FlexRow {
    align-items: center;
    display: flex;
    vertical-align: bottom;

    &__fixedLeft {
        justify-content: flex-start;

        &--padded {
            @extend .FlexRow__fixedLeft;

            margin-left: 1rem;
        }
    }

    &__fixedRight {
        justify-content: flex-end;

        &--padded {
            @extend .FlexRow__fixedRight;

            margin-right: 1rem;
        }
    }

    &__flexItem {
        flex-grow: 1;
    }
}
