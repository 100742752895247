footer {
    .FooterAbsolute {
        bottom: 0;
        position: absolute;
        width: 100%;
    }

    .FooterStyle {
        align-items: center;
        background-color: #2d395a;
        display: flex;
        height: 10vh;
        justify-content: center;
        padding: 1em 0;
        p {
            color: var(--white);
            font-size: 12px;
            font-weight: 600;
            margin-top: 1rem;
        }

        img {
            margin-left: 0.5em;
        }

        a {
            border-bottom: 0;
        }
    }
}
