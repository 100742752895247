@import 'assets/sass/colors';

.MuiChip-label {
    color: #002056;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
}

.name {
    color: #07090c;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    margin: 0.5em 0 0.5em 0.5em;
}
