@import 'assets/sass/colors';

.SegmentFilterChips {
    flex-wrap: wrap;
    margin-top: 0.5rem;

    .Chip {
        background-color: $engage-light !important;
        border: #c8cfd6 0.1rem solid !important;
        margin: 0.4rem;
    }
}
