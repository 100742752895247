.CampaignLibrary {
    background-color: #f7fbfc;
    margin: 0 0 2rem;
    overflow: auto;

    &__Body {
        // background-color: #fff;
        padding: 2rem;
        width: 70%;
    }

    &__Header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
    }

    &__Title {
        font-size: x-large;
    }
}
