@import 'assets/sass/_typography';
@import 'assets/sass/_devices';

.notification-container {
    @include setFont(14px, $Regular);

    height: 50px;
    // background: #fdf4eb 0% 0% no-repeat padding-box;
    margin: 0.5em;

    .notification-container_title {
        @include setFont(12px, $Regular);

        color: var(--black);
    }

    .notification-container_body {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
