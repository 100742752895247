.CampaignReportLinkDetails {
    margin-top: 2rem;

    h6 {
        font-weight: 700;
    }

    .MuiSvgIcon-root {
        margin-left: .5rem;
        padding-top: .25rem;
    }
}