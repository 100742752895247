@import 'assets/sass/colors';

.CampaignTile {
    width: 100%;
    border: 1px solid #c8cfd6;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    background-color: #fff;
    height: 11.25rem;

    .DraftTile,
    .CampaignCardDetails {
        padding: 1rem;
    }

    .SentGridContainer {
        height: 100%;
    }

    #CampaignTitle {
        color: $bold-text-color-campaignDetails !important;
        display: inline-block;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
    }

    .CampaignAnalytics {
        border-left: 1px solid #c8cfd6;
    }

    .AnalyticLabel {
        font-size: 12px;
        font-family: Roboto;
        color: #151d24;

        margin: 0 0 0 0;
    }

    .AnalyticPercents {
        font-size: 18px;
        font-family: Roboto;
        font-weight: bold;
        margin: 0 0 0 0;
    }

    .AnalyticDivider {
        border-left: 1px solid #c8cfd6;
        padding: 0.8rem;
    }

    .AnalyticBorder {
        border-right: 1px solid #c8cfd6;
        padding-right: 0.3rem;
        height: 4.5rem;
    }

    .AnalyticPadding {
        padding-left: 0.5rem;
    }
    .PendingAnalyticsContainer {
        text-align: center;
        width: 100%;
    }
    .PendingAnalyticsHeader {
        margin: 0;
        font-weight: 600;
        font-family: Roboto;
        font-size: 14px;
    }

    .PendingAnalyticsText {
        margin: 0;
        font-family: Roboto;
        font-size: 14px;
    }

    .SentContacts {
        font-size: 14px;
        font-family: Roboto;
        margin-bottom: 0;
        padding-top: 0.4rem;
        padding-right: 1rem;
    }

    #StatusSent {
        background-color: #caf9cb;
        display: inline-block;
        height: 1.5rem;
        margin-left: 1rem;
    }

    #StatusDraft {
        background-color: #f9dc99;
        display: inline-block;
        height: 1.5rem;
        margin-left: 1rem;
    }

    .MuiChip-label {
        color: #014538 !important;
        font-family: Roboto;
        font-weight: 500;
        line-height: 1.8;
    }

    #Eye {
        padding: 0 3%;
    }

    .DetailsGrid {
        padding-top: 1.5%;
    }

    .DetailsInfo {
        color: $bold-text-color-campaignDetails !important;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
    }

    .DetailsLabel {
        color: $thin-text-color-campaignDetails !important;
        font-family: Roboto;
        font-size: 14px;
    }

    .ReportingIcons {
        font-size: 24px;
    }
}
