.Table {
    border-spacing: 0;
    display: block;
    overflow-wrap: break-word;
    white-space: pre-wrap;

    .Tr {
        width: 100%;

        .Header {
            border-bottom: 0.1rem solid#c8cfd6;
            border-top: 0.1rem solid #c8cfd6;
            font-weight: 500;

            &__IsSorted {
                @extend .Header;

                background-color: #c8cacc;
            }

            &__IsNotSorted {
                @extend .Header;

                background-color: #eceff1;
            }
        }
        .Footer {
            border-bottom: 0.1rem solid#c8cfd6;
            border-top: 0.1rem solid#c8cfd6;
        }
    }

    .Th,
    .Td {
        background-color: var(--white);
        border-bottom: 0.1rem solid #eceff1;
        margin: 0;
        overflow: hidden;
        padding: 0.75rem;
        position: relative;
    }

    .ShorterTd {
        background-color: var(--white);
        border-bottom: 0.1rem solid #eceff1;
        margin: 0;
        overflow: hidden;
        padding: 0.45rem;
        position: relative;
    }

    &.Sticky {
        overflow: auto;

        .Header,
        .Footer {
            position: sticky;
            z-index: 2;
        }

        .Header {
            top: 0;
        }

        .Footer {
            bottom: 0;
        }

        .Body {
            position: relative;
            z-index: 0;
        }

        .Header[data-sticky-last-left-td] {
            z-index: 5 !important;
        }

        [data-sticky-td] {
            position: sticky;
        }

        :not(.Header__IsNotSorted)[data-sticky-td] {
            z-index: 1 !important;
        }

        [data-sticky-last-left-td] {
            border-right: 0.1rem solid#c8cfd6;
        }

        [data-sticky-first-right-td] {
            border-left: 0.1rem solid#c8cfd6;
        }
    }
}

.TableNavigation {
    margin: 0.5rem 0;

    .MuiInputBase-input,
    .MuiIconButton-root {
        background-color: white !important;
    }

    &__Left {
        @extend .TableNavigation;

        float: left;
        margin-left: 1rem;
        margin-top: 1rem;

        > * {
            margin-left: 0.75rem;
            vertical-align: middle !important;
        }
    }

    &__Right {
        @extend .TableNavigation;

        float: right;
        margin-right: 1rem;

        > * {
            margin-right: 0.75rem;
            vertical-align: middle !important;
        }
    }

    &__Overflow {
        @extend .TableNavigation;

        overflow-x: auto;
        padding-top: 0.5rem;
    }

    .PageNumbers {
        font-size: medium;
    }

    #goToPageInput {
        width: 4rem;
    }

    #previousPageButton,
    #nextPageButton {
        border: 0.1rem solid #bdc1c4;
        border-radius: 0.25rem;
        padding: 0.5rem !important;
    }
}

.TableNavigation > button,
.TableNavigation > div,
.TableNavigation > select,
.TableNavigation > input {
    background-color: transparent;
    margin: 0 0.4rem;
}

.RoundedInput {
    border: 0.1rem solid #bdc1c4;
    border-radius: 0.25rem;
    padding: 0 0.5rem;
}
