@import 'assets/sass/_typography';
@import 'assets/sass/_devices';
@import 'assets/sass/_utilities';
@import 'assets/sass/colors';

.info-page-footer {
    align-items: center;
    background-color: $engage-dark;
    box-shadow: 0 0.2rem 0.4rem #00000029;
    color: var(--white);
    display: flex;
    flex-direction: row;
    font-family: $montserrat;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;

    a {
        color: white;
        font-weight: bold;
        margin-left: 0.25rem;
    }


    @media only screen and (max-width: 600px) {
        display: unset;
        #a {
            grid-column: 1;
            grid-row: 1;
            text-align: center;
            font-size: 14px;
            justify-content: center;
        }
        #b {
            justify-content: space-between;
            grid-row: 2;
            grid-column: 1;
            text-align: center;
            font-size: 14px;
        }
    }

    .email {
        font-size: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 0.5rem;
        padding-right: 2rem;
    }

    .icon {
        position: relative;
        width: 1rem;
        height: 1rem;
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .flyer-button {
        border: 1px solid white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        color: white;
    }

    .level {
        display: flex;
        align-items: center;
        gap: 0.33rem;
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        margin-top: -0.75rem;
        margin-bottom: -0.75rem;
        text-align: center;
    }

    .flyer-text {
        font-size: 12px;
        position: relative;
        padding-top: 0.75rem;
    }
}
