.ReviewCampaign {
    display: grid;
    grid-template-columns: 45% 55%;

    .EditTemplate {
        margin-right: 0.5%;
        position: absolute !important;
        right: 0;
        z-index: 5;
    }

    .EditTemplateBtn {
        span {
            color: #fff;
            font-size: 14px;
        }
    }

    .ReviewForm {
        grid-column-start: 1;
        max-height: 70vh;
        overflow: auto;
        padding: 2em 5% 7rem;
        text-align: left;

        .CampaignName {
            color: #07090c;
            font-family: Roboto;
            font-size: 20px;
            font-weight: bold;
        }

        .ViewContactsBtnGrid {
            text-align: right;
        }

        .ViewContactsBtn {
            margin: 0.1rem;
        }

        .EditSegmentsBtn,
        .EditDetailsBtn {
            span {
                font-size: 14px;
            }
        }

        .SectionLabel {
            color: #07090c;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }

        .ItemLabel {
            color: #2b3a48;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 100;
            margin: 0 0 0.25em;
        }

        .SegmentLabel {
            margin: -1.5rem 0 0;
        }

        .name {
            color: #07090c;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            margin: 0.25em 0;
        }
    }

    .TemplatePreview {
        grid-column-start: 2;
        height: 80vh;
        overflow-y: auto;
    }
}

@media all and (max-width: 1100px) {
    .ReviewCampaign {
        grid-template-columns: 100% 0%;
    }

    .TemplatePreview {
        display: none;
    }
}

#viewContactsTable {
    border: 0.1rem solid #5a7085;
}
