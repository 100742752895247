@import 'assets/sass/colors';

.FilterSidebar {
    &__Header {
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        text-align: left;
    }

    &__Body {
        border-top: 0.1rem solid rgba(0, 0, 0, 0.12);
        height: calc(100vh - 18rem);
        overflow: auto;
        width: 100%;

        &--scrollable {
            overflow: hidden;
            overflow-y: auto;
        }
    }

    .DirtyField {
        background-color: $engage-light;

        &--exclude {
            background-color: #fff;
            margin: 0.5rem;
        }
    }

    .ConditionButtons {
        float: left;
        &__Right {
            float: right;
            > * {
                margin-left: 0.75rem;
            }
        }
    }

    .MuiAccordionSummary-content {
        color: #07090c;
        font-family: Roboto;
        font-weight: 500;
    }

    .MuiAccordionDetails-root > p {
        color: #2b3a48;
        font-family: Roboto;
    }
}

.FlexColumn {
    flex-direction: column;

    > * {
        margin: 0.5rem;
    }
}
