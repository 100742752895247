.AddContent {
    display: grid;
    grid-template-columns: 45% 55%;
    height: 64vh;

    .Form {
        grid-column-start: 1;
    }

    .TemplatePreview {
        grid-column-start: 2;
        height: 80vh;
        overflow: auto;
    }
}

@media all and (max-width: 1100px) {
    .AddContent {
        grid-template-columns: 100% 0%;
    }

    .TemplatePreview {
        display: none;
    }
}
