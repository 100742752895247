@import 'assets/sass/_typography';

.link-button {
    background: transparent;
    border: 0;
    color: var(--primary);
    height: fit-content;
    padding: 0;
    transition: none;
    width: fit-content;
}
