.AudienceSearchBar {
    border: 0.1rem solid #5a7085;
    border-radius: 0.25rem;

    > * {
        margin: 0 0 0 0.5rem;
    }
}

input[type='checkbox'] {
    transform: scale(1.5);
}

.SortIcon {
    float: right;
}

.Flip {
    transform: scaleX(-1) rotate(180deg);
}

.SearchIcon {
    float: left;
    margin-top: 4px;
}
