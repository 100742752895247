.SegmentCreator {
    background-color: #f7fbfc;
    padding-left: 2vw;
    padding-right: 3vw;
    padding-top: 3%;
    text-align: left;

    .ExitReportBtn {
        margin-bottom: 3%;
    }

    .box {
        background-color: white;
        border: 1px solid #c8cfd6;
        border-radius: 4px;

        .header {
            padding: 1rem;
            border-bottom: 1px solid #c8cfd6;
        }

        .steps {
            padding: 1rem;
        }

        .buttons {
            border-top: 1px solid #c8cfd6;
            padding: 1rem;
            text-align: right;

            > * {
                margin: 0 0.5rem;
            }
        }

        button {
            margin-right: 5px;
        }
    }

    .MuiInputLabel-formControl {
        transform: translate(12px, 14px) scale(1) !important;
    }

    .MuiInputLabel-formControl.MuiInputLabel-shrink {
        transform: translate(12px, -6px) scale(0.75) !important;
    }
}

.ViewSummary {
    &__Subheaders {
        font-size: 14px;
    }
    &__Text {
        padding: 0 0.1rem 0.5rem;
    }
}
