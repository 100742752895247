@import 'assets/sass/colors';

.CampaignAudience {
    height: 64vh;
    margin: 5% 7% 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;

    .Title {
        font-size: 20px;
    }

    .ViewContactsBtnGrid {
        text-align: right;
    }

    .ViewContactsBtn {
        margin: 0.1rem;
    }

    .FieldTitle {
        font-family: Roboto;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 3%;
    }

    .SelectGrid {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .SegmentSelect {
        display: inline-block;
        padding: 0 0.1rem;
        width: 99%;
        // color: $engage-primary;

        &__Validation > div {
            border: 0.15rem solid red;
        }
    }

    .AddSegmentBtn {
        color: $engage-primary;
        margin-left: 0.1rem;
        padding: 0 0.1rem;

        span {
            font-size: 13px;
            width: 100%;
        }
    }

    .SelectedSegmentsPlaceholder {
        color: #7e90a0;
        font-family: Roboto;
        margin-bottom: 0;
    }

    .name {
        color: #07090c;
        font-size: 14px;
        font-weight: 600;
        margin: 1em 0 0.25em;
    }
}

.css-1rhbuit-multiValue {
    border-radius: 0.8rem !important;
}

#viewContactsTable {
    border: 0.1rem solid #5a7085;
}
