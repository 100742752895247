.CampaignReportStats {
    border: 1px solid #c8cfd6;
    padding: 1rem;

    .HeadingText {
        color: #151d24;
        font-family: Roboto;
        font-size: 16px;
    }
    .SentContactsText {
        color: #151d24;
        font-family: Roboto;
        font-size: 18px;
    }

    .AnalyticLabel {
        font-size: 12px;
        font-family: Roboto;
        color: #151d24;

        margin: 0 0 0 0;
    }

    .AnalyticPercents {
        font-size: 18px;
        font-family: Roboto;
        font-weight: bold;
        margin: 0 0 0 0;
    }

    .AnalyticDivider {
        border-left: 1px solid #c8cfd6;
        padding: 0.8rem;
    }

    .AnalyticBorder {
        border-right: 1px solid #c8cfd6;
        padding-right: 0.3rem;
        height: 4.5rem;
        width: 6rem;
    }

    .NoLine {
        border-right: none;
        padding-left: 1rem;
    }

    .AnalyticPadding {
        padding-left: 0.5rem;
    }

    .Dash {
        border-top: 1px solid #c8cfd6;
        padding-bottom: 0.75rem;
        margin-left: -2.5rem;
    }
}
